/* istanbul ignore file */
import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { FieldType } from '@common/entities';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationStatus } from 'enums';

import { CirclePlusIcon, TrashIcon } from 'components/custom-icons';
import { DropDownOption } from 'components/fields/custom-fields/drop-down-group/type';
import notification from 'components/notification';

import SmartRouteNextStepDropDown from './SmartRouteNextStepDropDown';

const SmartRouteFieldRenderer = ({
  section,
  onChangeHandler,
  onDeleteOption,
  getAvailableSteps,
  updateField,
  setQuestions,
  onAddQuestion,
}) => {
  const { elements } = section;
  const queryClient = useQueryClient();

  /**
   * Handles field updates.
   */
  const handleBlurUpdate = async ({
    id,
    newValue,
    updateKey,
    options,
  }: {
    id: string;
    newValue: any;
    previousValue?: any;
    updateKey: string;
    options?: any[];
  }) => {
    const updatedOptions = options ? [...options] : undefined;
    try {
      await updateField({ id, [updateKey]: newValue, options: updatedOptions });

      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === id
            ? { ...q, [updateKey]: newValue, options: updatedOptions }
            : q,
        ),
      );
    } catch (err) {
      queryClient.invalidateQueries({ queryKey: ['smart-route'] });
      notification(
        'Error',
        err.message || `Failed to update ${updateKey}. Data re-synced.`,
        NotificationStatus.ERROR,
      );
    }
  };

  const handleStepSelection = async (
    fieldId: string,
    optionId: string,
    selectedStepValue,
  ) => {
    const availableGroups = getAvailableSteps(optionId);
    const allOptions = availableGroups.reduce(
      (acc, group) => acc.concat(group.children),
      [] as DropDownOption[],
    );
    const fullSelectedStep = allOptions.find(
      (option) => option.value === selectedStepValue.value,
    );

    const isApplication = fullSelectedStep.type === 'application';
    try {
      await updateField({
        id: fieldId,
        options: section.elements
          .find((el) => el.id === fieldId)
          ?.options.map((option) =>
            option.id === optionId
              ? {
                  ...option,
                  routeToApplicationId: isApplication
                    ? fullSelectedStep.value
                    : null,
                  routeToFieldId: isApplication ? null : fullSelectedStep.value,
                }
              : option,
          ),
      });
    } catch (err) {
      notification(
        'Error',
        err.message || 'Failed to update step.',
        NotificationStatus.ERROR,
      );
    }
  };

  return (
    <Box>
      {elements.map(({ id, label, type, options = [] }) => (
        <Box key={id} mb={6}>
          {type === FieldType.StaticText ? (
            <Box gridColumn="span 4" minH="120px" maxW="800px">
              <Textarea
                defaultValue={label}
                placeholder="Message Text"
                resize="none"
                minH="120px"
                onChange={(e) => onChangeHandler(id, e.target.value)}
                onBlur={(e) =>
                  handleBlurUpdate({
                    id,
                    newValue: e.target.value,
                    previousValue: label,
                    updateKey: 'label',
                  })
                }
              />
            </Box>
          ) : (
            <Box>
              <FormControl mb={4} maxW="800px">
                <FormLabel>Question</FormLabel>
                <Input
                  defaultValue={label}
                  onBlur={(e) =>
                    handleBlurUpdate({
                      id,
                      newValue: e.target.value,
                      previousValue: label,
                      updateKey: 'label',
                      options,
                    })
                  }
                />
              </FormControl>

              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                spacing={4}
                justifyContent="start"
              >
                {options.map(
                  ({
                    id: optionId,
                    label: optionLabel,
                    sequence: optionSequence,
                    name,
                  }) => (
                    <Flex key={optionId} maxW="400px" pos="relative" pl="30px">
                      <Box
                        as="span"
                        position="absolute"
                        left="0px"
                        top="38px"
                        w="20px"
                        h="20px"
                        borderRadius="20px"
                        border="1px solid"
                        borderColor="gray.500"
                      />
                      <Box
                        as="span"
                        position="absolute"
                        left="9px"
                        bottom="24px"
                        top="58px"
                        w="21px"
                        borderLeft="1px solid"
                        borderBottom="1px solid"
                        borderColor="gray.500"
                        borderBottomLeftRadius="xl"
                      />

                      <Box w="100%">
                        <FormControl>
                          <FormLabel>Option {optionSequence}</FormLabel>
                          <Input
                            mb={4}
                            placeholder={`Option ${optionSequence}`}
                            defaultValue={optionLabel}
                            onBlur={(e) => {
                              const updatedOptions = options.map((option) =>
                                option.id === optionId
                                  ? { ...option, label: e.target.value }
                                  : option,
                              );

                              handleBlurUpdate({
                                id,
                                newValue: updatedOptions,
                                previousValue: options,
                                updateKey: 'options',
                                options: updatedOptions,
                              });
                            }}
                          />
                        </FormControl>
                        <FormControl>
                          <SmartRouteNextStepDropDown
                            name={name}
                            placeholder="Select Step"
                            label="Next Step"
                            options={getAvailableSteps(optionId)}
                            defaultValue={
                              options.find((option) => option.id === optionId)
                                ?.target?.id ?? ''
                            }
                            onChange={(selectedStep) =>
                              handleStepSelection(id, optionId, selectedStep)
                            }
                            actionButtons={[
                              {
                                label: 'Add a Message',
                                onClick: () => {
                                  if (onAddQuestion) onAddQuestion('message');
                                },
                                icon: CirclePlusIcon,
                                iconColor: 'green.500',
                              },
                              {
                                label: 'Add a Qualifying Question',
                                onClick: () => {
                                  if (onAddQuestion) onAddQuestion('dropdown');
                                },
                                icon: CirclePlusIcon,
                                iconColor: 'green.500',
                              },
                            ]}
                            required
                          />
                        </FormControl>
                      </Box>

                      {options.length > 2 && (
                        <IconButton
                          mt={6}
                          ml={2}
                          aria-label="trash"
                          variant="ghost"
                          fontSize="26px"
                          color="red.700"
                          icon={<TrashIcon />}
                          onClick={() => {
                            if (!optionId) {
                              notification(
                                'Error',
                                'Option ID is missing and cannot be deleted.',
                                NotificationStatus.ERROR,
                              );
                              return;
                            }
                            onDeleteOption(optionId);
                          }}
                        />
                      )}
                    </Flex>
                  ),
                )}
              </SimpleGrid>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SmartRouteFieldRenderer;
